<template>
  <!-- 门店交易汇总 -->
  <TablePage ref="tablePage" v-model="options" />
</template>

<script>
import { tenantListPage } from '@/api/shop/base/shopCashier' //支付
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import TablePage from '@/components/tablePage/index.vue'
import { AllTenantAPI } from '@/api/system/tenant'
import { listShopInfo } from '@/api/shop/base/shopInfo'
import { shopTransactionSummary, shopTranToTal } from '@/api/tenant/saleData'
export default {
  name: 'RetailTotal',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  data() {
    return {
      options: {
        // 新增自定义合计计算
        customTotal: {
          apiHost: shopTranToTal,
          list: [
            {
              title: '总退款金额',
              key: 'totalRefundTradeMoney',
              value: '0.00'
            },
            {
              title: '总退款笔数',
              key: 'totalRefundTradeQty',
              value: '0.00'
            },
            {
              title: '总支付成功金额',
              key: 'totalTradeMoney',
              value: '0.00'
            },
            {
              title: '总支付成功笔数',
              key: 'totalTradeQty',
              value: '0.00'
            }
          ]
        },
        listNo: false,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          query: undefined, //商户名称、商户号、门店名称、门店编码、支付方式
          shopId: undefined, //门店id
          tenantId: undefined //商户id
        },
        getListApi: shopTransactionSummary,
        title: '交易统计',
        rowKey: 'idStr',
        type: 'idStr',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' },
              { filter: 'tenantNos', label: '商户号' },
              { filter: 'tenantNames', label: '商户名称' },
              { filter: 'shopNames', label: '交易门店' },
              { filter: 'billNos', label: '小票号' }
            ]
          },
          {
            type: 'local',
            label: '商户',
            model: '',
            filter: 'tenantIds',
            seniorSearch: true,
            option: {
              change: v => {
                this.options.search[4].option.remoteBody = { tenantIds: v }
                this.options.search[4].option.disabled = Boolean(!v?.length)
                this.options.search[5].option.remoteBody = { tenantIds: v }
                this.options.search[5].option.disabled = Boolean(!v?.length)
              },
              multiple: true,
              remote: AllTenantAPI,
              downLoad: 15,
              label: 'tenantName',
              value: 'tenantId',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            type: 'remote',
            label: '门店',
            model: '', //
            filter: 'shopIds',
            seniorSearch: true,
            option: {
              disabled: true,
              multiple: true,
              // remoteBody: () => {
              //   return { tenantId: this.$refs.tablePage.config.search[3].model }
              // },
              remote: listShopInfo,
              downLoad: 15,
              label: 'shopName',
              value: 'shopId',
              filterable: true,
              labels: [
                { title: '门店编号', label: 'shopNo' },
                { title: '门店名称', label: 'shopName' }
              ]
            }
          },
          {
            type: 'remote',
            label: '支付方式',
            model: '', //
            filter: 'payModeIds',
            seniorSearch: true,
            option: {
              disabled: true,
              multiple: true,
              remote: tenantListPage,
              downLoad: 15,
              label: 'payModeName',
              value: 'payModeId',
              filterable: true,
              labels: [
                { title: '编号', label: 'payModeNo' },
                { title: '名称', label: 'payModeName' }
              ]
            }
          },
          {
            label: "收款类型",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "payTypeIds",
            option: {
              multiple: true,
              data: [
                { payTypeName: "总部充值", payTypeId: "0" },
                { payTypeName: "门店充值", payTypeId: "1" },
                { payTypeName: "门店销售", payTypeId: "2" },
                { payTypeName: "门店预定", payTypeId: "3" },
                { payTypeName: "线上充值", payTypeId: "4" },
                { payTypeName: "自营商城", payTypeId: "5" },
                { payTypeName: "美团", payTypeId: "6" },
                { payTypeName: "饿了么", payTypeId: "7" },
                { payTypeName: "有赞", payTypeId: "8" },
                { payTypeName: "礼品卡", key: '9' },
                { payTypeName: "优惠券", key: '10' },
                { payTypeName: "次卡", key: '11' },
                { payTypeName: "PLUS会员", key: '12' }
              ],
              label: "payTypeName",
              value: "payTypeId",
              filterable: true,
            },
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        handleMultiple: {
          dataName: 'shopTransactionSummaryDetailResp',
          commonName: 'idStr'
        },
        columns: [
          { prop: 'sort', label: '序号', minWidth: '50' },
          { prop: 'shopNo', label: '门店编号', minWidth: '160' },
          { prop: 'shopName', label: '门店名称', minWidth: '160' },
          { prop: 'billDateStr', label: '日期', minWidth: '160' },
          { prop: 'tenantNo', label: '商户号', minWidth: '160' },
          { prop: 'tenantName', label: '商户名称', minWidth: '160' },
          { prop: 'payModeName', label: '收款方式', minWidth: '120' },
          { prop: 'paySuccessCount', label: '支付成功笔数', minWidth: '160' },
          { prop: 'paySuccessMoney', label: '支付成功金额', minWidth: '160' },
          { prop: 'payRefundCount', label: '退款笔数', minWidth: '160' },
          { prop: 'payRefundMoney', label: '退款金额', minWidth: '160' },
          { prop: 'transactionCount', label: '总交易笔数', minWidth: '160' },
          { prop: 'transactionMoney', label: '总交易金额', minWidth: '160' }
        ],
        list: []
      }
    }
  }
}
</script>
