<template>
  <!-- 商户排行 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { tenantRank, tenantRankTotal } from '@/api/tenant/saleData'
import TablePage from '@/components/tablePage/index.vue'
export default {
  name: 'RetailTotal',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  data() {
    return {
      options: {
        // 新增自定义合计计算
        customTotal: {
          apiHost: tenantRankTotal,
          list: [
            {
              title: '总交易笔数',
              key: 'totalTransactions',
              value: '0.00'
            },
            {
              title: '总交易金额',
              key: 'totalMoney',
              value: '0.00'
            }
          ]
        },
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          query: undefined //商户名称、商户号、门店名称、门店编码、支付方式
        },
        getListApi: tenantRank,
        title: '商户排行',
        rowKey: 'tenantNo',
        type: 'tenantNo',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'tenantNos', label: '商户号' },
              { filter: 'tenantNames', label: '商户名称' }
            ]
          },
          {
            label: "收款类型",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "payTypeIds",
            option: {
              multiple: true,
              data: [
                { payTypeName: "总部充值", payTypeId: "0" },
                { payTypeName: "门店充值", payTypeId: "1" },
                { payTypeName: "门店销售", payTypeId: "2" },
                { payTypeName: "门店预定", payTypeId: "3" },
                { payTypeName: "线上充值", payTypeId: "4" },
                { payTypeName: "自营商城", payTypeId: "5" },
                { payTypeName: "美团", payTypeId: "6" },
                { payTypeName: "饿了么", payTypeId: "7" },
                { payTypeName: "有赞", payTypeId: "8" },
                { payTypeName: "礼品卡", key: '9' },
                { payTypeName: "优惠券", key: '10' },
                { payTypeName: "次卡", key: '11' },
                { payTypeName: "PLUS会员", key: '12' }
              ],
              label: "payTypeName",
              value: "payTypeId",
              filterable: true,
            },
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'tenantNo', label: '商户号', minWidth: '160' },
          { prop: 'tenantName', label: '商户名称', minWidth: '160' },
          { prop: 'statisticalTime', label: '统计时间', minWidth: '160' },
          { prop: 'transactionCount', label: '交易笔数', minWidth: '160' },
          { prop: 'transactionMoney', label: '交易金额', minWidth: '160' }
        ],
        list: []
      }
    }
  }
}
</script>
