<template>
  <!-- 交易统计 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { merchantList, merchantTotal } from '@/api/tenant/saleData'
import TablePage from '@/components/tablePage/index.vue'
import { AllTenantAPI } from '@/api/system/tenant'
import { listShopInfo } from '@/api/shop/base/shopInfo'
import { tenantListPage } from '@/api/shop/base/shopCashier'
export default {
  name: 'RetailTotal',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  data() {
    return {
      getNumber: (data, payModeId) =>
        data?.find(x => x.payModeId === payModeId)?.payMoney || 0,
      options: {
        // 新增自定义合计计算
        customTotal: {
          apiHost: merchantTotal,
          list: [
            {
              title: '总交易笔数',
              key: 'totalTransactions',
              value: '0.00'
            },
            {
              title: '总收入',
              key: 'totalMoney',
              value: '0.00'
            }
          ]
        },
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          query: undefined, //商户名称、商户号、门店名称、门店编码、支付方式
          billNo: undefined, //小票编号
          tenantId: undefined, //商户id
          shopId: undefined, //门店id
          payModeId: undefined, //付款方式id
          billFactMoney: undefined //实付金额
        },
        getListApi: merchantList,
        title: '交易统计',
        rowKey: 'billId',
        type: 'billId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'tenantNos', label: '商户号' },
              { filter: 'tenantNames', label: '商户名称' },
              { filter: 'shopNames', label: '交易门店' },
              { filter: 'billNos', label: '小票号' }
            ]
          },
          {
            type: 'local',
            label: '商户',
            model: '',
            filter: 'tenantIds',
            seniorSearch: true,
            option: {
              change: v => {
                this.options.search[4].option.remoteBody = { tenantIds: v }
                this.options.search[4].option.disabled = Boolean(!v?.length)
                this.options.search[5].option.remoteBody = { tenantIds: v }
                this.options.search[5].option.disabled = Boolean(!v?.length)
              },
              multiple: true,
              remote: AllTenantAPI,
              downLoad: 15,
              label: 'tenantName',
              value: 'tenantId',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            type: 'remote',
            label: '门店',
            model: '', //
            filter: 'shopIds',
            seniorSearch: true,
            option: {
              disabled: true,
              multiple: true,
              remote: listShopInfo,
              downLoad: 15,
              label: 'shopName',
              value: 'shopId',
              filterable: true,
              labels: [
                { title: '门店编号', label: 'shopNo' },
                { title: '门店名称', label: 'shopName' }
              ]
            }
          },
          {
            type: 'remote',
            label: '支付方式',
            model: '', //
            filter: 'payModeIds',
            seniorSearch: true,
            option: {
              disabled: true,
              multiple: true,
              remote: tenantListPage,
              downLoad: 15,
              label: 'payModeName',
              value: 'payModeId',
              filterable: true,
              labels: [
                { title: '编号', label: 'payModeNo' },
                { title: '名称', label: 'payModeName' }
              ]
            }
          },
          {
            label: "收款类型",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "payTypeIds",
            option: {
              multiple: true,
              data: [
                { payTypeName: "总部充值", payTypeId: "0" },
                { payTypeName: "门店充值", payTypeId: "1" },
                { payTypeName: "门店销售", payTypeId: "2" },
                { payTypeName: "门店预定", payTypeId: "3" },
                { payTypeName: "线上充值", payTypeId: "4" },
                { payTypeName: "自营商城", payTypeId: "5" },
                { payTypeName: "美团", payTypeId: "6" },
                { payTypeName: "饿了么", payTypeId: "7" },
                { payTypeName: "有赞", payTypeId: "8" },
                { payTypeName: "礼品卡", key: '9' },
                { payTypeName: "优惠券", key: '10' },
                { payTypeName: "次卡", key: '11' },
                { payTypeName: "PLUS会员", key: '12' }
              ],
              label: "payTypeName",
              value: "payTypeId",
              filterable: true,
            },
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'tenantNo', label: '商户号', minWidth: '160' },
          { prop: 'tenantName', label: '商户名称', minWidth: '160' },
          { prop: 'shopName', label: '交易门店', minWidth: '200' },
          { prop: 'billNo', label: '小票号', minWidth: '260' },
          { prop: 'billFactMoney', label: '交易金额', minWidth: '200' },
          { prop: 'orderPayTime', label: '交易时间', minWidth: '160' },
          {
            prop: 'payTypse',
            align: 'center',
            label: '收款方式',
            children: () =>
              this.options.list?.[0]?.payTypes?.map?.(x => ({
                ...x,
                label: x.payModeName,
                prop: `payDetailResps.${x.payModeId}.payModeId.payMoney`,
                minWidth: 110,
                summary: true,
                getValue: (row, prop) =>
                  row.payDetailResps?.find(y => y.payModeId === x.payModeId)
                    ?.payMoney || 0
              })) || []
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'getListed':
          break
        default:
          break
      }
    }
  }
}
</script>
